.elementTransition {
  transition:
    opacity 0.6s cubic-bezier(0.65, 0, 0.35, 1),
    transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
  
  &.visible {
    opacity: 1;
  }
  
  &.hidden {
    opacity: 0;
    transform: translateY(20px);
  }
}